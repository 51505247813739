.journey-card {
    margin-bottom: 100px;
    /* Optional spacing between journey cards */
}


.right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* Align items vertically */
    background-color: white;


}

.left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* Align items vertically */



}

.j-card-container-l {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 5%;

}

.image-container {
    position: relative;
    width: 350px;
    height: 350px;
    background-color: rgb(105, 79, 79);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
    /* Ensure image doesn't overflow container */
}

.image-container img {
    width: 100%;
    /* Make image fill container horizontally */
    height: 100%;
    /* Make image fill container vertically */
    object-fit: cover;
    /* Cover container while preserving aspect ratio */
    position: absolute;
    /* Position image absolutely within container */
    top: 0;
    /* Position image at top of container */
    left: 0;
    /* Position image at left of container */
}

.j-img-title {
    position: absolute;
    bottom: -35px;
    /* Push the title 30px from the bottom */
    left: 220px;
    width: 100%;
    font-size: 3rem;
    text-align: left;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

}

.j-img-description {
    position: static;
    margin-top: 30px;
    margin-right: 0%;
    max-width: fit-content;
    /* Push the title 30px from the bottom */
    width: 80%;
    font-size: 1.2rem;
    text-align: Right;
    color: rgb(112, 112, 112);
}

.additional-desc {
    text-align: center;
    padding-left: 5%;
    color: rgb(112, 112, 112);
    position: relative;
}


.vertical-line-point {
    position: absolute;
    top: -15%;
    left: -1%;
    /* Adjust position as needed */
    width: 5px;
    /* Width of the line */
    height: 100%;
    /* Height of the line */
    background-color: rgba(255, 0, 0, .523);
    /* Color of the line */
}