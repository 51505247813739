.slick-prev:before, 
.slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: #dc3545;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    
}

.slider-container {
    width: 85%; /* Adjust the width as needed */
    margin: 0 auto; /* Center the slider container horizontally */
    padding-right: 7%;
  }
  
  .slick-slide {
    text-align: center; /* Center the slides */
  }
  
  
  /* Style each div within the Slider */
  .slider-container > div {
    margin-top: 50px;
    margin-left: 20px;
  }


  /* Style the slider items */
.slider-item {
    margin-left: 50px; /* Add left margin */
    display: grid; /* Use grid layout */
    place-items: center; /* Center items both horizontally and vertically */
  }
