.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    margin: 40px;
}

.contact-card {
    width: 800px;
    height: 400px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    background-size: cover;
    background-position: center;
    position: relative;
}

.contact-card .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* backdrop-filter: blur(1px); */
    /* background-color: rgba(255, 255, 255, 0.575); */
    background: linear-gradient(to right, rgba(243, 243, 243, 0.866) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.3) 70%, rgba(255, 255, 255, 0) 90%);
    border-radius: 10px;
    padding: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.unit-name {
    color: #4F5152;
    margin-bottom: 10px;
    cursor: pointer;
}

.description {
    color: #2188ED;
    margin-bottom: 10px;
}

.label {
    color: #2188ED;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
}

.text {
    color: #4F5152;
    font-size: 12px;
    margin-top: 0;
    font-weight: 500;
    cursor: pointer;
}

/* .text:hover {
    transform: scale(1.05);
    transition: transform 0.2s ;
} */

.info a {
    text-decoration: none;
    margin-top: 0px !important;
    margin-bottom: 10px;
}

.content .copy-button {
    background-color: #2188ED;
    color: #ffffff;
    border: none;
    padding: 10px 10px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 20px;
    align-self: flex-start;
    box-shadow: #2188ED;
}

.content.copy-button:hover {
    background-color: #067df5;
}