.image-card {
  width: 100%;
  height: 460px;
  border-radius: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin-top: 1rem;
}

.card-body {
  padding: 0;
}

.card-title {
  color: white;
  padding-bottom: 13px;
}

.square-corner {
  border-radius: 0px;
}

.image-overlay-c {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 400px;
  background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.6) 40%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%);
  color: white;
  padding: 20px;

  display: flex;
  /* Use flexbox for alignment */
  flex-direction: column;
  /* Stack children vertically */
  justify-content: flex-end;
  /* Align children to the bottom */
}

.content {
  position: relative;
  z-index: 1;
}

.ht-ct {
  height: 500px;
  object-fit: cover;
}



.card-description {
  margin: 0;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: height 0.3s, opacity 0.3s;
}

.image-card:hover .card-description {
  height: 200px;
  /* Adjust height as needed */
  opacity: 1;
}

.image-card:hover .card-title {
  bottom: 100%;
}

.learn-more-link {
  color: white;
  text-decoration: none;
  position: relative;
  margin-left: 60%;
  margin-right: auto;
}


.learn-more-link::after {
  content: '';
  display: block;
  margin-top: 5px;
  margin-left: 60%;
  margin-right: auto;
  max-width: 110px;
  width: 0;
  height: 2px;
  background-color: white;
  /* Change color as needed */
  transition: width 0.3s ease;
  /* Add transition for width */
}

.learn-more-link:hover::after {
  width: 100%;
  /* Expand underline on hover */
}

.learn-more-link:hover .arrow-icon {
  opacity: 1;
  /* Show arrow icon on hover */
  margin-left: 2%;
  /* Adjust margin for smooth appearance */
}

.arrow-icon {
  opacity: 0;
  /* Initially hidden */
  margin-left: 5px;
  transition: opacity 0.3s, margin-left 0.3s;
  /* Add transition for smooth appearance */
}