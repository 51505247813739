.section-header {
  margin-left: 15%;
  margin-right: 15%;
  text-align: center;
  padding-top: 4rem;
}

.title {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.subtitle {
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 1.4rem;
}

.paragraph {
  text-align: justify;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 320;
  /* Use a value like 300 for a thinner font */

}



@media (max-width: 767.98px) {
  .section-header {
    margin-left: 10%;
    margin-right: 10%;
  }

  .title {
    font-size: 1.8rem;
  }

  .subtitle {
    font-size: 1.1rem;
  }

  .paragraph {
    font-size: 1rem;
  }
}