.no-gutters {
    padding-right: 0;
    padding-left: 0;
}

.div-timeline-wrapper {
    position: relative;
}



.journey-content {
    position: relative;
    overflow-x: hidden;
}

.vertical-line {
    position: absolute;
    top: 0%;
    /* Top margin */
    bottom: 0%;
    /* Bottom margin */
    left: 50%;
    /* Center horizontally */
    width: 3px;
    /* Width of the line */
    background-color: rgba(0, 0, 0, 0.189);
    /* Color of the line */
}