.sec-navbar {
  height: 45px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.sec-navbar {
  position: sticky;
  top: -50px;
  transition: top 0.5s ease-in-out;
  width: 100%;
}

.visible-h {
  top: 0;
}

.hidden-h {
  top: -50px;
}

.drop-menu-item-link {}



.nav-link {
  font-size: 1.3rem;
  color: #4571a2;
  padding-right: 20px !important;
  font-weight: 400;
}


.nav-wrapper .nav-link {
  color: #4571a2;
  /* Set the default link color */
  text-decoration: none;
  /* Remove the default underline */
  position: relative;
  /* Positioning for pseudo-element */
  transition: color 0.3s;
  /* Smooth transition for color change */
}


.nav-wrapper .nav-link:hover {
  color: #263f5d;
  /* Change color on hover */
}


.no-arrow>.dropdown-toggle::after {
  display: none !important;
}


.nav-line {
  position: absolute;
  bottom: 1px;
  /* adjust this value according to your design */
  left: 0;
  width: 100%;
  height: 4px;
  /* height of the line */
  background-color: #4b77ad;
  /* color of the line */
  transform-origin: left;
  /* set the transform origin to the left */
  transform: scaleX(0);
  /* initially hide the line */
  transition: transform 0.3s ease;
  /* add transition effect */
}

.nav-wrapper:hover .nav-line {
  transform: scaleX(1);
  /* expand the line on hover */
}

.nav-wrapper {
  position: relative;
}

#nested-nav-dropdown {
  font-size: 1.1rem !important;
  color: #4571a2;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
}


/* Style for dropdown menu items */
.dropdown-menu .dropdown-item {
  font-size: 1.1rem;
  color: #4571a2;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
}

/* Style for dropdown menu items on hover */
.dropdown-menu .dropdown-item:hover {
  background-color: #f8f9fa;
  /* Change background color on hover */
}

.dropdown-menu {
  border: none;
  /* Remove borders */
  border-radius: 5px;
  /* Remove rounded corners */
  height: auto;
  width: auto;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.085);

  /* Hide overflow content */
}

/* Animation for showing dropdown menu
@keyframes showDropdown {
  from {
    height: 100px;
  }
 
  to {
    height: auto;
  }
} */



/* Show dropdown menu */
.dropdown-menu.show {
  animation: showDropdown 0.01s linear forwards;
  /* Apply animation */

}

@media (max-width: 991px) {
  #basic-navbar-nav {
    background-color: white;
    margin-top: -5px;
    padding-bottom: 50px;
  }

  .dropdown-menu {
    max-height: 400px;
    overflow-y: auto;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.085);
    margin: 0;
  }

}

.nav-brand-main {
  margin-left: 7.5%;
  margin-right: 2%;
}

.logo-nav-main {
  width: 0;
  /* Set initial width to 0 */
  transition: width 0.3s ease;
  /* Define transition for width property */
  display: none;
}

.logo-nav-main.logo-stuck {
  margin-top: 2px;
  width: 160px;
  /* Transition to 150px when navbar is stuck */
  display: inline-block;
}



.additional-nav-mobile-menu {
  display: none;
}

#basic-navbar-main-menu {
  margin-top: 40px;
}

.navbar-toggler {
  margin-top: -3%;
}

@media only screen and (max-width: 991px) {

  .additional-nav-mobile-menu {
    display: block;
    padding-left: 10%;
  }

  .nav-wrapper {
    padding-left: 5%;
  }


  #nested-nav-dropdown {
    padding-left: 10px;
  }

  #nested-nav-dropdown.dropdown-menu .dropdown-item {
    padding-left: 20px !important;
  }

  .dropdown-menu .dropdown-item {
    padding-left: 10px;
  }
}