.margin20 {
  margin-left: 20px;
}

.white-bg {
  background-color: white !important;
}

.navbar {
  display: flex;
  justify-content: space-between !important;
}


.left-align-items {
  display: flex;
  align-items: flex-end;
}

.c-nav-menu {
  display: flex;
  justify-content: space-evenly !important;
  width: 600px;
  margin-left: 10%;
}

.c-nav-menu .nav-link {
  font-size: 0.8rem;
  color: #35587F;
}

.c-nav-menu .nav-link:hover {
  color: #555;
  /* Change color on hover */
}

.nav-link svg {
  margin-right: 5px;
}

.custom-nav-logo {
  width: 250px;
  height: 98px;

}

@media only screen and (max-width: 991px) {

  .custom-nav-menu {
    display: none;
  }
}