.margin-0 {
    margin: 0px;
}


.red-background {
    background: #ff8282;
}

.fic-stage-image-container {
    width: 100%;
}

.fic-img-center-stage {
    width: 100%;
    height: 600px !important;
    object-fit: cover;
    position: relative;
}

.fic-stage-container {
    position: relative;
    margin: 0;
    max-height: fit-content;
    /* margin-bottom: 20px; */
}


.fic-stage-container>.row>* {
    padding-right: 0px;
    padding-left: 0px;
}

.fic-stage-text-container {
    position: relative;
    display: flex;
    flex-direction: row;
    /* background-color: #F8F8F8; */
    /* margin-top: 20px; */
    z-index: 5;
    width: 80%;
    margin: 0 10%;
    opacity: 0;
    animation: fadeIn 1s forwards;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

@keyframes fadeIn {
    from {
        top: 0px;
        /* Start position */
        opacity: 0.7;
        /* Start opacity */
    }

    to {
        top: -150px;
        /* End position */
        opacity: 1;
        /* End opacity */
    }
}

.fic-left-part {
    background-color: #F8F8F8;
    color: black;
    padding: 20px;
    flex: 1;
    height: 100%;
}

.fic-right-part {
    background-color: #1E334B;
    color: white;
    padding: 20px;
    flex: 1;
    font-size: 1.3rem;
    height: 100%;
    font-weight: 320;
}

.fic-right-part ul {
    list-style: none;
    padding-left: 0;
}

.fic-title {
    color: #30557D;
    font-size: 2.3rem;
    margin-bottom: 1rem;
    letter-spacing: 0.025em;
    font-weight: 500;
    line-height: 1.2;
}

.fic-subtitle {
    font-size: 20px;
    margin-bottom: 1.5rem;
    font-weight: lighter;
    line-height: normal;
}

.fic-check-mark>svg {
    width: 35px;
    height: 35px;
}

@media (max-width: 767px) {
    .fic-img-center-stage {
        height: auto !important;
        max-height: none !important;
        object-fit: contain;
        /* Ensure the full image is displayed without cropping */
    }

    .fic-stage-text-container {
        margin: 0;
        border-radius: 0;
        width: 100%;
        position: static;
        /* Adjust to prevent text overlay on image */
        box-shadow: none;
    }

    .fic-title {
        font-size: 1.8rem;
    }

    .fic-subtitle {
        font-size: 1.1rem;
    }

    .fic-right-part {
        font-size: 0.8rem;
    }

    .fic-left-part,
    .fic-right-part {
        padding: 15px;
    }

    .fic-stage-container {
        margin: 0;
        padding: 0;
    }
}