/* homepage.css */
.custom-container {
    margin-left: auto; /* 10% left margin */
    margin-right: auto; /* 10% right margin */
    padding-bottom: 50px;
  }
  

  .flex-components{
    display: flex;
    justify-content: space-evenly;
  }

  .cards{
    display: flex;
    margin: 20px;
    gap: 2rem;
    justify-content: center;
  }

  .img-txt-div{
    margin: 10px auto;
    max-width:75%;
  }

  .home-imageContainer {
    width: 96%;
    margin: 0 2% 0 2%;
  }
  
  .home-img {
    width: 100%;
    height: auto;
    /* This maintains the aspect ratio of the image */
  }