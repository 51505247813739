.text-caption-header {
  margin-left: 15%;
  margin-right: 15%;
  text-align: left;
}

.text-caption-title {
  font-size: 2rem;
  margin-bottom: 1rem;

  padding-top: 4rem;
}

.text-caption-subtitle {
  margin-bottom: 1rem;
  text-align: left;
  font-size: 1.4rem;
}

.text-caption-paragraph {
  text-align: left;
  font-size: 1.3rem;
  font-weight: 320;
  /* Use a value like 300 for a thinner font */

}



.text-caption-bullets {
  margin-left: 10%;
  font-size: 1.3rem;
  font-weight: 320;
  list-style-type: disc;
  /* Set the list style type to disc */   
}

@media (max-width: 767.98px) {
  .text-caption-header {
    margin-left: 10%;
    margin-right: 10%;
  }

  .text-caption-title {
    font-size: 1.8rem;
  }

  .text-caption-subtitle {
    font-size: 1.1rem;
  }

  .text-caption-paragraph {
    font-size: 1rem;
  }

  .text-caption-bullets {
    margin-left: 10%;
    font-size: 1rem;
    font-weight: 320;
    list-style-type: disc;
    /* Set the list style type to disc */
  }
}