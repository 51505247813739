/* * {
    margin: 0;
    padding: 0;
  }
  
  body {
    padding: 20px;
    background: #eee;
    user-select: none;
  } */

#threeD-slider {
    position: relative;
    width: 100%;
    height: 35vw;
    perspective: 1000px;
    transform-style: preserve-3d;
    overflow: hidden;
    /* Hide overflow to manage image transitions */
}

.threeD-slide {
    position: absolute;
    top: 20%;
    left: 30%;
    width: 40%;
    height: 60%;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    transform-origin: center;
    opacity: 0;
    /* Hidden by default */
    filter: blur(0);
    /* No blur by default */
}

.threeD-carousel-caption {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 10px;
    color: #fff;
}

.threeD-carousel-caption h3 {
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.threeD-carousel-caption p {
    font-size: 1rem;
}

.prev-btn,
.next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.4);
    border: none;
    color: white;
    padding: 10px 0px;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 10;
}

.prev-btn {
    left: 4%;
}

.next-btn {
    right: 4%;
}

/* Add animations for smooth transitions */
.threeD-slide {
    transition: transform 0.6s ease, opacity 0.6s ease, filter 0.6s ease;
}

@media (max-width: 768px) {
    #threeD-slider {
        height: 50vw;
        /* Adjust height for mobile */
    }

    .threeD-slide {
        width: 80%;
        /* Increase width for mobile */
        height: 80%;
        /* Adjust height for mobile */
        top: 10%;
        /* Adjust top position for mobile */
        left: 10%;
        /* Center the slide */
    }

    .threeD-carousel-caption {
        bottom: 10px;
        /* Adjust caption position */
        padding: 5px;
        /* Reduce padding */
    }

    .prev-btn,
    .next-btn {
        padding: 5px;
        /* Reduce button padding */
        font-size: 1.2rem;
        /* Adjust button font size */
        background-color: rgba(0, 0, 0, 0.6);

    }

    .prev-btn {
        left: 6%;
    }

    .next-btn {
        right: 6%;
    }
}