/* ImageText.css */

.imgtxt-container {
  max-width: 60%;
  margin-top: 3rem;
}


@media (max-width: 767.98px) {
  .imgtxt-container {
    max-width: 80%;
  }
}

:root {
  --ms2-ratio: 1.5625;
  ;
  /* Define your custom variable */
}

.ImageText-description {
  text-align: left;
}

.ImageText-title {
  color: #30557d;
  font-size: calc(var(--ms2-ratio) * 1rem);
  font-weight: 500px;
}

.ImageText-title2 {
  color: #30557d;
  /* font-size: calc(var(--ms2-ratio) * 2rem); */
  font-weight: 500px;
}

.ImageText-Paragraph {
  color: #333;

  font-weight: 400;
  line-height: 1.75rem;
}

.ImageText-List li {
  margin-bottom: 10px;
  /* Add margin-bottom to create space between list items */
}

.ImageText-button {
  background-color: rgb(215, 16, 45);
  /* Button background color */
  color: #ffffff;
  /* Text color */
  border: 1px solid;
  /* Border */
  padding: 10px 50px;
  /* Padding */
  font-size: 14px;
  /* Font size */
  cursor: pointer;
  /* Cursor style */
  border-radius: 4px;
  /* Border radius */
  transition: background-color 0.3s ease;
  /* Smooth transition for background color */
  font-weight: 500;
  text-decoration: none;
}

/* Hover state */
.ImageText-button:hover {
  background-color: rgb(153, 6, 28);
  /* Darker background color on hover */
}

.ImageText-img {
  height: 320px;
  border-radius: 20px;
  box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.085);

}


@media (max-width: 767px) {
  .ImageText-img {
    margin-bottom: 20px;
  }
}