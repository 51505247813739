.journey-card-r {
    margin-bottom: 100px;
    /* Optional spacing between journey cards */
}

.j-card-container-r {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5%;

}

.image-container-r {
    position: relative;
    width: 350px;
    height: 350px;
    background-color: rgb(105, 79, 79);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
    /* Ensure image doesn't overflow container */
}

.image-container-r img {
    width: 100%;
    /* Make image fill container horizontally */
    height: 100%;
    /* Make image fill container vertically */
    object-fit: cover;
    /* Cover container while preserving aspect ratio */
    position: absolute;
    /* Position image absolutely within container */
    top: 0;
    /* Position image at top of container */
    left: 0;
    /* Position image at left of container */
}

.j-img-title-r {
    position: absolute;
    bottom: -35px;
    /* Push the title 30px from the bottom */
    left: 20px;
    /*chanded for r*/
    width: 100%;
    font-size: 3rem;
    text-align: left;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

}

.j-img-description-r {
    position: static;
    margin-top: 30px;
    margin-left: 0%;
    max-width: fit-content;
    /* Push the title 30px from the bottom */
    width: 80%;
    font-size: 1.2rem;
    text-align: left;
    /*Changed for R*/
    color: rgb(112, 112, 112);
}

.additional-desc-r {
    padding-right: 5%;
    color: rgb(112, 112, 112);
    position: relative;
}


.vertical-line-point-r {
    position: absolute;
    top: -15%;
    right: -3%;
    /* Adjust position as needed */
    /* Adjust position as needed */
    width: 5px;
    /* Width of the line */
    height: 100%;
    /* Height of the line */
    background-color: rgba(255, 0, 0, .523);
    /* Color of the line */
}