.features-card {
  width: 300px;
  border-radius: 20px;
  box-shadow: rgba(193, 193, 193, 0) 0px 12px 32px 0px; /* Adjust shadow values as needed */
  margin: 30px auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content horizontally */
  justify-content: center; /* Center the content vertically */
  text-align: center; /* Center the text within the div */
  transition: box-shadow 0.3s ease; /* Smooth transition for box-shadow */
}

.features-card:hover {
  box-shadow: rgb(193, 193, 193) 0px 12px 32px 0px; /* Adjust shadow values as needed */
}


  
  .features-card-image {
    width: auto;
    height: 87px;
    margin-top: 1rem;
    object-fit: cover;
    
  }
  
  .features-card-content {
    padding: 20px;
  }
  
  .features-card-title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .features-card-description {
    font-size: 14px;
    color: #666;
  }
  