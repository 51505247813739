.full-window {
  display: flex;
  justify-content: center;
  /* Horizontally center the content */
  align-items: center;
  /* Vertically center the content */
  width: 100%;
  height: 100vh;
}



.centered-div {
  width: 100%;
  /* Adjust the width as needed */
  height: 55%;
  /* Adjust the height as needed */
  background-color: rgb(248, 248, 248);
  /* Grey background color */
  display: flex;
  position: relative;
  flex-wrap: wrap;


}

.btn-pink {
  background-color: #f2e9e9;
  padding: 0.6rem 2.3rem;

  font-size: 18px;

  position: relative;
  z-index: 1;
  min-width: 300px;
  border-radius: 0.2rem;
  color: rgb(40, 70, 101);
  border-color: transparent;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  text-align: center;

}



.btn-pink::before {
  content: "";
  background-color: #d40606;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: all 0.5s;
  z-index: -1;
  min-height: 40px;


}

.btn-pink:hover::before {
  transform: scale(1);
  border-radius: 0.3rem;
  text-decoration-color: white;
}

.btn-pink:hover {
  color: white;
  /* Set text color to white on hover */
}

.button-container {
  display: flex;
  gap: 2rem;
  margin-top: 20px;

}

.checklist {
  list-style-type: none;
  padding: 0;
  color: rgb(57, 51, 51);
  margin-bottom: 30px;
}

.li-promoter {
  color: rgb(215, 16, 45);
  font-weight: bold;
  height: 1.5rem;


}

.checklist li {
  margin-bottom: 10px;
  /* Adjust the spacing as needed */
  font-size: 18px;
  /* Adjust the font size as needed */
  line-height: 1.5;
  /* Adjust the line height as needed */
}




.centered-div-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  display: block;
  width: 40%;
  height: 40%;
  margin-left: 60px;
  margin-top: 80px;
  margin-bottom: 20px;
  gap: 2rem;
  box-sizing: border-box;
}

.Karton-card-image {
  height: 620px;
  width: 600px;
  position: absolute;
  right: 100px;
  /* Position the image to the right */
  top: -30%;
  /* Position the image vertically centered */
  transform: translateY(-50%);
  /* Adjust to vertically center the image */
}

.centered-div-title {
  color: rgb(48, 85, 125);
  margin-bottom: 20px;

}