.custom-component-container {
    margin-bottom: 20px;
    /* Add margin bottom for spacing */
}

.custom-title {
    color: #28588F;
    /* Title color */
    font-size: 3rem;
    /* Title font size */
    margin: 5% 20%;

}

@media (max-width: 768px) {

    .custom-title {
        font-size: 2rem;
        /* Adjust font size for mobile view */
    }

    .custom-paragraph {
        /* Add styles for the paragraph as needed */
        margin: 5% 10%;
        font-size: 1rem;
        text-align: center;
        /* Justify the text */
    }
}

.custom-image {
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.244);
    /* Add shadow to the image */
    width: 50%;
    /* Ensure image width is 100% of container */
    height: auto;
    /* Allow image height to adjust proportionally */
}

.custom-subtitle {
    font-size: 1.5rem;
    /* Subtitle font size */
}

.custom-paragraph {
    /* Add styles for the paragraph as needed */
    margin: 5% 20%;
    font-size: 1.3rem;
    font-weight: 320;
        text-align:center;
    /* Justify the text */
}