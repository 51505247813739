.full-image-text-container {
    margin-left: 10%;
    margin-right: 10%;

}

.full-image-text-row {
    /* Add styles for the row if needed */
}

.full-image-text-column {
    /* Add styles for the column if needed */
}

.overlap-container {
    position: relative;
}

.image-wrapper {
    width: 150%;
    /* Adjust as needed */
    height: 400px;
    /* Adjust as needed */
    margin-left: 15%;
    /* Adjust as needed */
    margin-bottom: 40%;
    /* Adjust as needed */
    overflow: hidden;
}

.full-image {
    width: 100%;
    height: auto;
}


.flt-text-content-wrapper {
    position: absolute;
    top: 300px;
    left: 50%;
    width: 150%;
    /* height: 80%; */
    background-color: rgb(237, 244, 255);
    border-radius: 50px;

    padding: 5%;
    /* Adjust padding as needed */
    z-index: 1;
    /* Ensure this overlaps the image */
    text-justify: distribute-all-lines;

}


@media (max-width: 767px) {

    .full-image-text-container {
        margin-left: 1%;
        margin-right: 1%;

    }

    .flt-text-content-wrapper {
        border-radius: 0px;

        position: static;
        width: 100%;
        padding: 0;
        background-color: rgb(237, 244, 255);
        z-index: auto;
        /* Adjust this value as needed */
        margin-left: 0;
        text-align: center;
        /* Center the text in mobile view */
        padding-bottom: 5%;
    }

    .image-wrapper {
        /* Adjust as needed */
        height: auto;
        width: 100%;
        margin-left: 0;
        margin-bottom: 0;
        overflow: hidden;
    }


}