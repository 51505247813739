.r-section-header {
    text-align: center;
    padding: 3rem 0;
    color: white;
    background-color: #D7344C;
}

.r-section-header-center {
    margin-left: 20%;
    margin-right: 20%;
}

.r-title {
    font-size: 3rem;
    margin-bottom: 1rem;
    letter-spacing: 0.025em;
}

.r-subtitle {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1.4rem;
}

.r-paragraph {
    text-align: justify;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 320;
    margin-bottom: 1.5rem;
}

.r-btn-pink {
    background-color: #f2e9e9;
    padding: 0.6rem 2.3rem;
    font-size: 1rem;
    font-weight: 500;
    position: relative;
    z-index: 1;
    min-width: 300px;
    border-radius: 0.2rem;
    color: #D7344C;
    border-color: transparent;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    text-align: center;
}

.r-btn-pink:hover {
    color: #fa304e;
}

@media (max-width: 767.98px) {
    .r-section-header-center {
        margin-left: 10%;
        margin-right: 10%;
    }

    .r-title {
        font-size: 1.8rem;
    }

    .r-subtitle {
        font-size: 1.1rem;
    }

    .r-paragraph {
        font-size: 1rem;
    }

    .r-btn-pink {
        font-size: 0.8rem;
    }
}