:root {
  --ms2-ratio: 1.5625: --ms0-ratio: 1;

}

.img-ContactBubble img {

  height: 250px;
  width: 250px;
  margin-left: 200px;
  border-radius: 50%;

}



.Container-ContactBubble {
  margin-top: 10px;
}

.Desc-ContactBubble {
  padding-right: 30x;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  margin-bottom: 5rem;
  /* Adjust height as needed */
  margin-left: -10%;
}

.ContactBubble-Heading {
  color: #30557d;
  font-weight: 500;
  font-size: calc(var(--ms2-ratio) * 1rem);
}

.ContactBubble-SubHeading {
  font-size: calc(var(--ms0-ratio) * 1rem);
  line-height: 1.5;
  margin-bottom: 1rem
}

.Desc-ContactBubble {
  font-size: calc(var(--ms0-ratio) * 1rem);
  margin-bottom: 2rem
}

.ContactBubble-ListHeading {
  font-size: calc(var(--ms2-ratio) * 0.7rem);
  font-weight: bold;
  margin-bottom: 1rem;
}


.custom-col2 {
  width: 510px;
}

@media screen and (max-width: 576px) {
  .center-container {
    margin-left: 10px;
    /* Reset margin for centering on smaller screens */
    margin-top: 10px;
  }

  .custom-col2 {
    width: 100%;
    /* Make the second column take up full width on smaller screens */
  }

  .Desc-ContactBubble {
    padding-right: 0;
    /* Remove padding for smaller screens */
  }

  .img-ContactBubble img {
    margin-left: 50px;
    margin-top: 200px;
    /* Reset margin for the image on smaller screens */
  }

  .ContactBubble-Heading {
    margin-top: 10px;
  }
}